export const colors = {
    white: '#FFFFFF',

    // HWB color space
    // https://www.palcon.nl/colors/Color-hwb.htm

    'blue-50': '#EDF8FF', //    203 H,  93 W,   0 B
    'blue-100': '#DEF2FF', //   204 H,  87 W,   0 B
    'blue-200': '#CFECFC', //   202 H,  81 W,   1 B
    'blue-500': '#268FCC', //   202 H,  15 W,  20 B
    'blue-600': '#0070A3', //   204 H,   0 W,  36 B
    'blue-650': '#006594', //   199 H,   0 W,  42 B
    'blue-700': '#005E8A', //   199 H,   0 W,  46 B

    'gray-50': '#F8F8F8', //      0 H,  97 W,   3 B
    'gray-100': '#F2F2F2', //     0 H,  95 W,   5 B
    'gray-150': '#E5E5E5', //     0 H,  90 W,  10 B
    'gray-200': '#CCCCCC', //     0 H,  80 W,  20 B
    'gray-300': '#B2B2B2', //     0 H,  70 W,  30 B
    'gray-400': '#999999', //     0 H,  60 W,  40 B
    'gray-500': '#808080', //     0 H   50 W,  50 B
    'gray-600': '#707070', //     0 H,  44 W,  56 B
    'gray-700': '#545454', //     0 H,  33 W,  67 B

    'green-200': '#DFF5C7', //   88 H,  78 W,   4 B
    'green-700': '#407800', //   88 H,   0 W,  53 B

    'orange-200': '#F5E6CF', //  37 H,  81 W,   4 B
    'orange-400': '#FFA514', //  37 H,   8 W,   0 B
    'orange-600': '#915A00', //  37 H,   0 W,  43 B
    'orange-700': '#6E3A00', //  32 H,   0 W,  57 B

    'red-50': '#FCE8E3', //      12 H,  89 W,   1 B
    'red-500': '#DD3806', //     14 H,   2 W,  13 B
    'red-600': '#CF3000', //     14 H,   0 W,  19 B

    'yellow-200': '#FFF4B8', //  51 H,  72 W,   0 B

    black: '#000000',
    'shadow-black': 'rgba(0, 0, 0, 0.2)',
};
